import {
  COLLECTIONS_LOAD_SUCCESS,
  COLLECTIONS_CREATE_STARTED,
  COLLECTIONS_CREATE_FAILED,
  COLLECTIONS_CREATE_SUCCESS,
  COLLECTIONS_UPDATE_STARTED,
  COLLECTIONS_UPDATE_FAILED,
  COLLECTIONS_UPDATE_SUCCESS,
  COLLECTIONS_DELETE_STARTED,
  COLLECTIONS_DELETE_FAILED,
  COLLECTIONS_DELETE_SUCCESS,
} from '../constants'

const initialState = {
  loaded: false,
  items: [],
  isLoading: false,
  error: null,
}

export default function reducer(state = initialState, action = { type: null }) {
  switch (action.type) {
    case COLLECTIONS_LOAD_SUCCESS:
      return {
        ...state,
        items: [...action.payload.collections],
        loaded: true,
      }
    case COLLECTIONS_CREATE_STARTED:
    case COLLECTIONS_UPDATE_STARTED:
    case COLLECTIONS_DELETE_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case COLLECTIONS_CREATE_FAILED:
    case COLLECTIONS_UPDATE_FAILED:
    case COLLECTIONS_DELETE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case COLLECTIONS_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        items: [...state.items, action.payload.collection],
      }
    case COLLECTIONS_UPDATE_SUCCESS: {
      const items = [...state.items]
      const foundItem = items.findIndex(
        (i) => i.id === action.payload.collection.id
      )
      if (foundItem > -1) items[foundItem] = action.payload.collection
      return {
        ...state,
        isLoading: false,
        error: null,
        items,
      }
    }
    case COLLECTIONS_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        items: [
          ...state.items.filter((i) => i.id !== action.payload.collection.id),
        ],
      }
    default:
      return state
  }
}
