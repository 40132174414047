const easeInOutQuad = (t, b, c, d) => {
  let nT = t / (d / 2)
  if (nT < 1) return (c / 2) * nT * nT + b
  nT -= 1
  return (-c / 2) * (nT * (nT - 2) - 1) + b
}

const easeInSine = (t, b, c, d) =>
  -c * Math.cos((t / d) * (Math.PI / 2)) + c + b

// scrollTo(100); - scroll window to 100
// scrollTo(300, { target: '#layout' }); - scroll div with id 'layout' to 300;
const scrollTo = (to, options = null) => {
  const element =
    options && options.target ? document.getElementById(options.target) : window
  // console.log(element);
  const duration = (options && options.duration) || 500
  const callback = (options && options.callback) || null

  const start = element.scrollTop || element.scrollY
  const change = to - start
  const increment = 10
  let currentTime = 0
  let scrollInterval = null

  const animateScroll = () => {
    currentTime += increment
    const val = easeInSine(currentTime, start, change, duration)
    if (element.self === window) {
      window.scrollTo(0, val)
    } else {
      element.scrollTop = val
    }
    if (currentTime >= duration) {
      clearInterval(scrollInterval)
      if (callback) callback()
    }
  }
  scrollInterval = setInterval(animateScroll, increment)
}

export default scrollTo
