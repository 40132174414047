import { useReducer, createContext } from 'react'
import reducers from 'reducers'

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

const augmentDispatch = (dispatch, state) => (input) =>
  typeof input === 'function' ? input(dispatch, state) : dispatch(input)

const GlobalContextProvider = ({ children }) => {
  const { reducer, initialState } = reducers()
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={augmentDispatch(dispatch, state)}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
