import combineReducers from '../lib/combineReducers'

import sites from './sites'
import header from './header'
import account from './account'
import collections from './collections'
// import { INIT_REDUCERS } from '../constants'

const reducers = () => {
  const [reducersCombined, initialStateCombined] = combineReducers({
    sites: [sites, sites()],
    header: [header, header()],
    account: [account, account()],
    collections: [collections, collections()],
  })
  return {
    reducer: reducersCombined,
    initialState: initialStateCombined,
  }
}

export default reducers
