/* eslint-disable import/prefer-default-export */

import isModellinkSite from '../helpers/isModellinkSite';

export const LIVE_SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || (isModellinkSite() ? 'https://www.modellink.se' : 'https://www.selectmodel.com')
export const LIVE_SITE_CMS = process.env.NEXT_PUBLIC_SITE_CMS || 'https://cms.selectmodel.com'
export const SOLARNET_URL = 'https://select.solarnet.app'

export const GA_TAG_SELECT = 'UA-150859325-1'
export const GA_TAG_MODELLINK = 'UA-123239885-1'
export const GA_TAG = isModellinkSite() ? GA_TAG_MODELLINK : GA_TAG_SELECT

export const TELEPHONE_NUMBER = ''
export const EMAIL_ADDRESS = 'info@selectmodel.com'

export const CLOUDINARY_URL = 'https://res.cloudinary.com/select-models'

export const INIT_REDUCERS = 'INIT_REDUCERS'

export const HEADER_SCROLLED = 'HEADER_SCROLLED'

export const NEWS_POSTS_PER_PAGE = 20

export const PAGE_LOAD_STARTED = 'PAGE_LOAD_STARTED'
export const PAGE_LOAD_FADE_OUT = 'PAGE_LOAD_FADE_OUT'
export const PAGE_LOAD_SUCCESS = 'PAGE_LOAD_SUCCESS'

export const LIGHTBOX_ADD_ITEM = 'LIGHTBOX_ADD_ITEM'
export const LIGHTBOX_ADD_ITEM_FAILED = 'LIGHTBOX_ADD_ITEM_FAILED'
export const LIGHTBOX_ADD_ITEM_SUCCESS = 'LIGHTBOX_ADD_ITEM_SUCCESS'
export const LIGHTBOX_REMOVE_ITEM = 'LIGHTBOX_REMOVE_ITEM'
export const LIGHTBOX_REMOVE_IMAGE = 'LIGHTBOX_REMOVE_IMAGE'

export const LIGHTBOX_LOADED = 'LIGHTBOX_LOADED'

export const LIGHTBOX_CLEAR = 'LIGHTBOX_CLEAR'

export const LIGHTBOX_UPDATE_NAME = 'LIGHTBOX_UPDATE_NAME'

export const LIGHTBOX_CREATE_STARTED = 'LIGHTBOX_CREATE_STARTED'
export const LIGHTBOX_CREATE_FAILED = 'LIGHTBOX_CREATE_FAILED'
export const LIGHTBOX_CREATE_SUCCESS = 'LIGHTBOX_CREATE_SUCCESS'

export const LIGHTBOX_UPDATE_STARTED = 'LIGHTBOX_UPDATE_STARTED'
export const LIGHTBOX_UPDATE_FAILED = 'LIGHTBOX_UPDATE_FAILED'
export const LIGHTBOX_UPDATE_SUCCESS = 'LIGHTBOX_UPDATE_SUCCESS'

export const LIGHTBOX_SEND_STARTED = 'LIGHTBOX_SEND_STARTED'
export const LIGHTBOX_SEND_FAILED = 'LIGHTBOX_SEND_FAILED'
export const LIGHTBOX_SEND_SUCCESS = 'LIGHTBOX_SEND_SUCCESS'

export const LIGHTBOX_DELETE_STARTED = 'LIGHTBOX_DELETE_STARTED'
export const LIGHTBOX_DELETE_FAILED = 'LIGHTBOX_DELETE_FAILED'
export const LIGHTBOX_DELETE_SUCCESS = 'LIGHTBOX_DELETE_SUCCESS'

export const LIGHTBOX_DUPLICATE_STARTED = 'LIGHTBOX_DUPLICATE_STARTED'
export const LIGHTBOX_DUPLICATE_FAILED = 'LIGHTBOX_DUPLICATE_FAILED'
export const LIGHTBOX_DUPLICATE_SUCCESS = 'LIGHTBOX_DUPLICATE_SUCCESS'

export const LIGHTBOX_CLEAR_RECENTLY_UPDATED = 'LIGHTBOX_CLEAR_RECENTLY_UPDATED'

export const NEWS_FETCH_STARTED = 'NEWS_FETCH_STARTED'
export const NEWS_FETCH_FAILED = 'NEWS_FETCH_FAILED'
export const NEWS_FETCH_SUCCESS = 'NEWS_FETCH_SUCCESS'

export const ARTISTS_FETCH_STARTED = 'ARTISTS_FETCH_STARTED'
export const ARTISTS_FETCH_FAILED = 'ARTISTS_FETCH_FAILED'
export const ARTISTS_FETCH_SUCCESS = 'ARTISTS_FETCH_SUCCESS'

export const FLASH_MESSAGE_CLEAR = 'FLASH_MESSAGE_CLEAR'

export const ACCOUNT_SIGNUP_STARTED = 'ACCOUNT_SIGNUP_STARTED'
export const ACCOUNT_SIGNUP_FAILED = 'ACCOUNT_SIGNUP_FAILED'
export const ACCOUNT_SIGNUP_SUCCESS = 'ACCOUNT_SIGNUP_SUCCESS'

export const ACCOUNT_LOGIN_STARTED = 'ACCOUNT_LOGIN_STARTED'
export const ACCOUNT_LOGIN_FAILED = 'ACCOUNT_LOGIN_FAILED'
export const ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS'

export const ACCOUNT_UPDATE_CLEAR = 'ACCOUNT_UPDATE_CLEAR'

export const ACCOUNT_LOGIN = 'ACCOUNT_LOGIN'
export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT'

export const ACCOUNT_UPDATE_STARTED = 'ACCOUNT_UPDATE_STARTED'
export const ACCOUNT_UPDATE_FAILED = 'ACCOUNT_UPDATE_FAILED'
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS'

export const ACCOUNT_DELETE_STARTED = 'ACCOUNT_DELETE_STARTED'
export const ACCOUNT_DELETE_FAILED = 'ACCOUNT_DELETE_FAILED'
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS'

export const ACCOUNT_VALIDATION_STARTED = 'ACCOUNT_VALIDATION_STARTED'
export const ACCOUNT_VALIDATION_FAILED = 'ACCOUNT_VALIDATION_FAILED'
export const ACCOUNT_VALIDATION_SUCCESS = 'ACCOUNT_VALIDATION_SUCCESS'

export const SITES_FETCH_STARTED = 'SITES_FETCH_STARTED'
export const SITES_FETCH_FAILED = 'SITES_FETCH_FAILED'
export const SITES_FETCH_SUCCESS = 'SITES_FETCH_SUCCESS'

export const SITES_SELECTED = 'SITES_SELECTED'

export const OFFICE_FETCH_STARTED = 'OFFICE_FETCH_STARTED'
export const OFFICE_FETCH_FAILED = 'OFFICE_FETCH_FAILED'
export const OFFICE_FETCH_SUCCESS = 'OFFICE_FETCH_SUCCESS'

export const COLLECTIONS_CREATE_STARTED = 'COLLECTIONS_CREATE_STARTED'
export const COLLECTIONS_CREATE_FAILED = 'COLLECTIONS_CREATE_FAILED'
export const COLLECTIONS_CREATE_SUCCESS = 'COLLECTIONS_CREATE_SUCCESS'
export const COLLECTIONS_LOAD_STARTED = 'COLLECTIONS_LOAD_STARTED'
export const COLLECTIONS_LOAD_FAILED = 'COLLECTIONS_LOAD_FAILED'
export const COLLECTIONS_LOAD_SUCCESS = 'COLLECTIONS_LOAD_SUCCESS'
export const COLLECTIONS_UPDATE_STARTED = 'COLLECTIONS_UPDATE_STARTED'
export const COLLECTIONS_UPDATE_FAILED = 'COLLECTIONS_UPDATE_FAILED'
export const COLLECTIONS_UPDATE_SUCCESS = 'COLLECTIONS_UPDATE_SUCCESS'
export const COLLECTIONS_DELETE_STARTED = 'COLLECTIONS_DELETE_STARTED'
export const COLLECTIONS_DELETE_FAILED = 'COLLECTIONS_DELETE_FAILED'
export const COLLECTIONS_DELETE_SUCCESS = 'COLLECTIONS_DELETE_SUCCESS'
