import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'
import { useRouter } from 'next/router'

import isModellinkSite from '../../helpers/isModellinkSite';

import scrollTo from 'helpers/scrollTo'

import WordpressLink from 'components/WordpressLink'

import s from './Footer.module.css'

function Footer({ globalSettings, className }) {
  const router = useRouter()
  const { site } = router.query
  const showBfmaLogo = site === 'london'

  const [ref, inView] = useInView({
    threshold: 0.5,
  })

  const handleScrollTo = () => {
    scrollTo(0)
  }

  const { facebookHandle, instagramHandle, twitterHandle } =
    globalSettings?.offices?.nodes?.[0]?.acfOffices || {}

  const socialLinks = [
    {
      social: 'Facebook',
      href: 'https://www.facebook.com/',
      handle: facebookHandle,
    },
    {
      social: 'Instagram',
      href: 'https://www.instagram.com/',
      handle: instagramHandle,
    },
    {
      social: 'Twitter',
      href: 'https://twitter.com/',
      handle: twitterHandle,
    },
  ]

  const footerMenu = globalSettings?.footerMenu?.acfMenuItems?.menu || null

  return (
    <div
      id="footer"
      className={classNames(s.container, className, {
        [s.rotateBtn]: inView,
      })}
    >
      <div className={s.wrapper}>
        <div className={s.row}>
          <div className={s.btnWrap}>
            <button
              type="button"
              className={s.scrollTopBtn}
              onClick={handleScrollTo}
            />
          </div>
          {footerMenu?.map((m, i) => (
            <div key={`${m[0]}${i}`} className={s.links}>
              {m?.items.map((props) => (
                <WordpressLink
                  key={`${props.url}_${props.label}`}
                  className={s.link}
                  {...props}
                />
              ))}
            </div>
          ))}
          {socialLinks && (
            <div className={s.links}>
              {socialLinks
                .filter((f) => f.handle)
                .map(({ href, handle, social }, i) => (
                  <a
                    key={`${handle}${i}`}
                    href={`${href}${handle}`}
                    target="_blank"
                    rel="reopener noreferrer"
                    className={s.link}
                  >
                    {social}
                  </a>
                ))}
            </div>
          )}
        </div>
        <div ref={ref} className={classNames(s.row, s.rowCopy)}>
          <div className={s.copySelect}>
            <span className={s.copy}>
              &copy; {new Date().getFullYear()} {isModellinkSite() ? 'Modellink' : 'Select Model Management'}
            </span>
          </div>

          {showBfmaLogo && (
            <a
              href={'https://bfma.fashion/'}
              rel={'reopener noreferrer'}
              target={'_blank'}
            >
              <img
                src={'/img/bfma.png'}
                width={125}
                height={58}
                alt={'British Fashion Model Agents Association'}
              />
            </a>
          )}

          <div className={s.copyPatron}>
            <a
              href="https://patron.studio"
              target="_blank"
              rel="reopener noreferrer"
              className={s.copy}
            >
              site by patron
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  globalSettings: PropTypes.object,
  className: PropTypes.string,
}
Footer.defaultProps = {
  globalSettings: {},
  className: '',
}

export default Footer
