import { HEADER_SCROLLED, FLASH_MESSAGE_CLEAR } from '../constants'
import { parseCookies } from 'nookies'

const checkFlashMessageCookie = () => {
  const cookies = parseCookies()

  if (cookies['flash-message-read']) {
    return true
  }
  return false
}

const initialState = {
  scrolled: false,
  flashMessageCleared: checkFlashMessageCookie(),
}

export default function reducer(state = initialState, action = { type: null }) {
  switch (action.type) {
    case HEADER_SCROLLED:
      return {
        ...state,
        scrolled: action.payload,
      }
    case FLASH_MESSAGE_CLEAR:
      return {
        ...state,
        flashMessageCleared: true,
      }
    default:
      return state
  }
}
